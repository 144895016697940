import yaokiskiService from '../../lib/yaokiski'
import shallow from 'zustand/shallow'
import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import useDidMountEffect from '../../lib/hooks/useDidMountEffect'
import DatePicker from 'react-date-picker';




export default function Home() {
    const [startDate, setStartDate] = useState(new Date());
    const history = useHistory();
    const [type, setType] = useState('');
    const [typeDos, setTypeDos] = useState(false);
    const [input, setInput] = useState({
        "age_range": 'menor de 18',
        "gender": 'M',
        "email": '',
        "emailConfirm": '',
        "ticket_number": '',
        // "ticket_number": '017700340096200605422552',
        "purchase_date": new Intl.DateTimeFormat("az").format(new Date()),
        "amount": '',
        // "amount": '1225.50',
        "g_recaptcha_token" : '',
    });
    const [errors, setErrors] = useState({});
    const [validForm, setValidForm] = useState(true);
    const MySwal = withReactContent(Swal);
    const [priceCupon, setPriceCupon] = useState('0');


    const {setToken,postRequest,getRequest,getData,check,url,memory} = yaokiskiService(state => ({
        setToken: state.setToken,
        postRequest: state.postRequest,
        getRequest: state.getRequest,
        getData: state.getData,
        check: state.check,
        url: state.url,
        memory: state.memory,
    }), shallow)

    // const rootId = document.getElementById( 'root' );
    // rootId.style.backgroundColor="#ffcd00";

    useEffect(() => {
        console.log(memory);
        getPriceCupon()
    }, [])
    //detecta el cambio del valor de type
    useDidMountEffect(() => {
        let dateFormatOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};

        setInput({...input,purchase_date:new Intl.DateTimeFormat("az",dateFormatOptions).format(new Date()),ticket_number:'',amount:''});
    }, [type]);

    useEffect(() => {
        //console.log(startDate);
        let dateFormatOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};

        let newdateformat = new Intl.DateTimeFormat("az", dateFormatOptions).format(startDate)
        setInput({...input,purchase_date:newdateformat});
        console.log(newdateformat);
    },[startDate])

    const validEmailRegex = RegExp(
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    // console.log( rootId );
   
    const action = () => {
        
        // setTypeDos(true);
        let data_errors = {};
        let form_valid = true;
        
        data_errors.email =  !validEmailRegex.test(input.email) ? 'Correo-e requerido' : '';
        data_errors.emailConfirm =  !validEmailRegex.test(input.emailConfirm) ? 'Correo-e requerido' : input.emailConfirm != input.email ? 'Correo-e no es igual': '';
        data_errors.type =  !type ? 'Tipo requerido' : '';
        data_errors.ticket_number =  '';
        data_errors.purchase_date =  !input.purchase_date || (input.purchase_date == '1969-12-31')  ? 'Fecha requerida' : '';
        data_errors.amount =  !input.amount  ? 'Monto requerido' : '';

        if (type == 'shop') {
            if (input.ticket_number?.length < 1) 
                data_errors.ticket_number = 'Número de pedido requerido'
        }else if(type == 'ecommerce'){
            if (input.ticket_number?.length < 1) 
                data_errors.ticket_number = 'Número de pedido requerido'
        }

        setErrors(data_errors);

        // handleSubmit2();
        // console.log(errors);

        Object.values(data_errors).forEach(val => val.length > 0 && (form_valid = false));

        if (!form_valid) {
            MySwal.close();
            return;
        }

        MySwal.fire({
            "allowOutsideClick": false,
            "title":  type == 'shop' ? 'Estamos validando su número de ticket' : 'Estamos validando su número de pedido',
            "text": "Espere un momento por favor.",
        })
        MySwal.showLoading();

        window.grecaptcha.ready(function() {
            window.grecaptcha.execute('6Lc9bIQcAAAAAOjH5al43-hndoUptX5448_aul3p')
            .then(function(token) {
                let newData = input;

                newData.g_recaptcha_token = token;

                postRequest(url.api+'/api/v1/check-ticket', newData)
                .then(function (response){
                    if (check(response)) {
                        let data = getData(response);
                        setToken(data.data);
        
                        // const results = await apiCall(url.api+'/api/v1/app/questionnaire');
                        
                        getRequest(url.api+'/api/v1/app/questionnaire/'+data.data)
                        .then(function (response_questionnaire){
                            MySwal.close();
                            if (check(response_questionnaire)) {
                                console.log(response_questionnaire.data);
                                yaokiskiService.setState({ memory: { "quest" : getData(response_questionnaire.data),"count_quest" : response_questionnaire.data.count_questions}})
                                // yaokiskiService.setState({ memory: { "count_quest" : response_questionnaire.data.count_questions}})

                                history.push("/encuesta")
                            }else{
        
                            }
                        }, ( error ) => {
                            MySwal.fire( {
                                "iconColor": '#ffcd00',
                                "confirmButtonColor":'#000000',
                                "confirmButtonText": "Aceptar",
                                "text": "Por el momento no se puede realizar la operación, intente de nuevo más tarde.",
                                "title": "Atención",
                                "icon": "error",
                                "footer": '<p style="font-size:9px;">'+error+'</p>'
                            } );
                        });
                        
                    }else{
                        MySwal.fire( {
                            "confirmButtonText": "Aceptar",
                            "text": response.data.message,
                            "title": "Atención",
                            "icon": "warning",
                            "iconColor": '#ffcd00',
                            "confirmButtonColor":'#000000',
                        } );
                    }
                }, ( error ) => {
                    MySwal.fire( {
                        "iconColor": '#ffcd00',
                        "confirmButtonColor":'#000000',
                        "confirmButtonText": "Aceptar",
                        "text": "Por el momento no se puede realizar la operación, intente de nuevo más tarde.",
                        "title": "Atención",
                        "icon": "error",
                        "footer": '<p style="font-size:9px;">'+error+'</p>'
                    } );
                });
            });
        });
    }
    
    const getPriceCupon = () => {
        getRequest(url.api+'/api/v1/app/current-rule')
                .then(function (responsePrice){
                    if (check(responsePrice)) {
        
                        console.log(responsePrice.data.data.amount);
                        setPriceCupon(responsePrice.data.data.amount)
                       
                        
                    }else{
                        MySwal.fire( {
                            "confirmButtonText": "Aceptar",
                            "text": responsePrice.data.message,
                            "title": "Atención",
                            "icon": "warning",
                            "iconColor": '#ffcd00',
                            "confirmButtonColor":'#000000',
                        } );
                    }
                }, ( error ) => {
                    MySwal.fire( {
                        "iconColor": '#ffcd00',
                        "confirmButtonColor":'#000000',
                        "confirmButtonText": "Aceptar",
                        "text": "Por el momento no se puede realizar la operación, intente de nuevo más tarde.",
                        "title": "Atención",
                        "icon": "error",
                        "footer": '<p style="font-size:9px;">'+error+'</p>'
                    } );
                });
    }

    const setMinDate = () => {
        let date = new Date();
        date.setDate( date.getDate() - 30 );

        return date;
    }

    return (
        <>
            <div className="modal fade" id="exampleModal"  aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">¿Donde encontrar el número de tu ticket?</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        <img className="img-fluid ticket-example" src={`${process.env.PUBLIC_URL}/assets/images/ticket.jpg`} alt="logo"/>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-office" data-bs-dismiss="modal">Cerrar</button>
                    </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="modalTerms"  aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        <img className="img-fluid " src={`${process.env.PUBLIC_URL}/assets/images/terminos_new.png`} alt="términos"/>

                    </div>
                    {/* <div className="modal-footer">
                        <button type="button" className="btn btn-office" data-bs-dismiss="modal">Cerrar</button>
                    </div> */}
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className="row justify-content-center pt-5 pb-5 text-center">
                    {/* <h1>TU VOZ <span className='max'>AL MAX</span></h1> */}
                    <div className="col-12 col-md-8 text-center">
                        {/* <br /> */}
                        <p className='description'>Queremos seguir siendo tu mejor opción, por ello deseamos conocer cómo fue tu experiencia al comprar en OfficeMax. <br/><br/> Responde la encuesta y obtén un <strong>cupón de descuento</strong> válido para tu siguiente compra.</p>
                        {/* <hr></hr> */}
                    </div>
                </div>
            </div>
            <div className='form-principal'>
                <div className='container'>
                    <form className="pb-5" >
                        <div className="row">
                            <div className="col-md-6 col-12 text-left">
                                <h2 className='titlesform'>TUS DATOS</h2>

                                <div className='row'>
                                    <div className='col-md-6 col-12'>
                                        <div className="form-group">
                                            <label className="mt-3 mb-1 label-max" ><b>EDAD</b></label>
                                            <select id="age_range" name="age_range" className="form-select input-quest" 
                                                onChange={({ target: { value } }) => setInput({...input, age_range: value})}
                                            >
                                                <option value="menor de 18">Menor de 18 años</option>
                                                <option value="18 a 24 años">18 a 24 años</option>
                                                <option value="25 a 34 años">25 a 34 años</option>
                                                <option value="35 a 44 años">35 a 44 años</option>
                                                <option value="45 a 54 años">45 a 54 años</option>
                                                <option value="55 a 64 años">55 a 64 años</option>
                                                <option value="65 años o más">65 años o más</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-12'>
                                        <div className="form-group">
                                            <label className="mt-3 mb-1 label-max" ><b>GÉNERO</b></label>
                                            <select name="gender" id="gender" className="form-select input-quest" 
                                                onChange={({ target: { value } }) => setInput({...input, gender: value})}
                                            >
                                                <option value="M">Masculino</option>
                                                <option value="F">Femenino</option>
                                                <option value="O">Prefiero no responder</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="form-group">
                                            <label className="mt-3 mb-1 label-max" ><b>CORREO-E</b></label>
                                            <input className="form-control input-quest" type="text" 
                                                value={input.email}
                                                onChange={({ target: { value } }) => setInput({...input, email: value})}
                                                placeholder='usuario@dominio.com'

                                            />
                                            {errors?.email && 
                                            <span className='error error-quest'>{errors?.email}</span>}
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="form-group">
                                            <label className="mt-3 mb-1 label-max" ><b>CONFIRMACIÓN DE CORREO-E</b></label>
                                            <input className="form-control input-quest" type="text" 
                                                value={input.emailConfirm}
                                                onChange={({ target: { value } }) => setInput({...input, emailConfirm: value})}
                                                placeholder='usuario@dominio.com'

                                            />
                                            {errors?.emailConfirm && 
                                            <span className='error error-quest'>{errors?.emailConfirm}</span>}
                                        </div>
                                    </div>
                                </div>
                                <br />
                            </div>
                            <div className="col-md-6 col-12 ">
                                <h2 className='titlesform'>LOS DATOS DE TU COMPRA</h2>
                                <div className='row'>
                                    <div className='col-12'>
                                        <label className="mt-3 mb-1" ><b>MÉTODO DE COMPRA</b></label>
                                        
                                        <div className="form-group pt-1 pb-3">
                                            <input type="radio" className="form-check-input "  value="shop" name="buy" id="shop" onChange={({ target: { value } }) => setType(value)}/>
                                            <label className="form-check-label label-max ms-2" htmlFor="shop"> Tienda física</label> 
                                        
                                            <input type="radio" className="form-check-input  ml-10"  value="ecommerce" name="buy" id="ecommerce" onChange={({ target: { value } }) => setType(value)} />
                                            <label className="form-check-label label-max ms-2" htmlFor="ecommerce"> En linea</label>
                                            
                                        </div>
                                        {errors?.type && 
                                                <span className='error error-quest'>{errors?.type}</span>}
                                    </div>
                                    <div className='col-12'>
                                        <div className={`${type ? "d-block" : "d-none"}`} id="ticket" >
                                        {/* <div id="ticket" > */}
                                            <div className={`form-group ${type == 'shop' ? "d-block" : "d-none"}`}>
                                                <label className="mt-2 mb-1 label-max" ><b>NÚMERO DE TICKET</b> <a type="button" className="" data-bs-toggle="modal" data-bs-target="#exampleModal"> ¿Donde lo encuentro?</a></label>
                                                <input className="form-control input-quest" type="tel" maxLength="36"
                                                    value={input.ticket_number}
                                                    onChange={({ target: { value } }) => setInput({...input, ticket_number: value})}
                                                    placeholder='0000000000000000'
                                                />
                                                {errors?.ticket_number && 
                                                <span className='error error-quest'>{errors?.ticket_number}</span>}
                                            </div>
                                            <div className={`form-group ${type == 'ecommerce' ? "d-block" : "d-none"}`}>
                                                <label className="mt-2 mb-1 label-max" ><b>NÚMERO DE PEDIDO</b></label>
                                                <input className="form-control input-quest" type="tel" maxLength="16"
                                                    value={input.ticket_number}
                                                    onChange={({ target: { value } }) => setInput({...input, ticket_number: value})}
                                                    placeholder='0000000000000000'
                                                />
                                                {errors?.ticket_number && 
                                                <span className='error error-quest'>{errors?.ticket_number}</span>}
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6 col-12'>
                                                    <div className="form-group">
                                                        <label className="mt-3 mb-1 label-max" ><b>FECHA</b></label>
                                                        
                                                        <DatePicker
                                                            format = "yyyy-MM-dd"
                                                            minDate={setMinDate()}
                                                            value={startDate}
                                                            onChange={setStartDate}
                                                        />
                                                        {errors?.purchase_date && 
                                                        <span className='error error-quest'>{errors?.purchase_date}</span>}
                                                    </div>
                                                </div>
                                                <div className='col-md-6 col-12'>
                                                    <div className="form-group">
                                                        <label className="mt-3 mb-1 label-max" ><b>MONTO</b></label>
                                                        <input className="form-control input-quest" type="number" min="1" step="any" 
                                                            value={input.amount}
                                                            onChange={({ target: { value } }) => setInput({...input, amount: value})}
                                                        />
                                                        {errors?.amount && 
                                                        <span className='error error-quest'>{errors?.amount}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                    
                                </div>
                                
                                    
                                {/* </div> */}
                                
                                <br />
                            </div>
                            <div className="text-center pt-5">
                                <button  type="button" onClick={action} className="btn btn-office">INICIAR ENCUESTA </button>
                                
                               


                            </div>
                        </div>
                        
                    </form>
                </div>
            </div>

            <div className='container pt-5 pb-5'>
                <div className='row'>
                    <div className='col-12 text-center'>
                        <a href="#" data-bs-toggle="modal" data-bs-target="#modalTerms">Términos y condiciones</a>
                        {/* <p>Cupón de $ {priceCupon}.00 M.N. aplicables en montos mínimos de $500.00 M.N. No acumulable. Un cupón por compra. Aplica en tiendas físicas y tienda en línea consulte productos participantes o en www.officemax.com.mx. Vigencia de la promoción al 31 de diciembre del 2022.</p> */}
                    </div>
                </div>
            </div>
        </>
    )
}
