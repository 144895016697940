// import axios from "axios";
import create from 'zustand'
import shallow from 'zustand/shallow'
import apiCall from "./api";

const yaokiskiService = create((set, get) => ({
        url: {
            "api": "https://api.tuvozalmax.com",
            "page": "",
            "token" : ""
        },
        setToken: (token) => {
            try {
                localStorage.setItem("token", token)
            } catch (error) {
                localStorage.removeItem("token")
            }
        },
        getToken: () => {
            try {
                let token = localStorage.getItem("token")
                return token;
            } catch (error) {
                localStorage.removeItem("token")
            }
        },
        getHeaders: (isGET = false) => {
            return {
                "Content-Type": isGET ? "x-www-form-urlencoded" : "application/json",
                "Authorization": "Bearer " + this.getToken()
            };
        },
        getRequest: async (endpoint) => {
            try {
                const results = await apiCall(endpoint,'get');
                return results;
            } catch (error) {
                console.log('algo ocurrio');
            } finally {
                console.log('request end');
            }
        },
        postRequest: async (endpoint,data) => {
            try {
                const results = await apiCall(endpoint,'post', data);
                return results;
            } catch (error) {
                console.log('algo ocurrio');
            } finally {
                console.log('request end');
            }
        },
        putRequest: async (endpoint,data) => {
            try {
                const results = await apiCall(endpoint,'put', data);
                return results;
            } catch (error) {
                console.log('algo ocurrio');
            } finally {
                console.log('request end');
            }
        },
        deleteRequest: async (endpoint,data) => {
            try {
                const results = await apiCall(endpoint,'delete');
                return results;
            } catch (error) {
                console.log('algo ocurrio');
            } finally {
                console.log('request end');
            }
        },
        getData : (response) => {
            return response.data || response || null;
        },
        check : (response) => {
            if(!response || ! response.data.status || response.data.status !== 'success' )
                return false;

            return true;
        },
        isWarning : (response) => {
            if(!response || ! response.data.status || response.data.status !== 'warning' )
			    return false;

	        return true;
        },
        memory:{}

}),shallow)
    
export default yaokiskiService;