import React from 'react'
import yaokiskiService from '../../lib/yaokiski'
import shallow from 'zustand/shallow'
import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";


export default function Vale() {

    const {postRequest,getRequest,getData,check,url,memory} = yaokiskiService(state => ({
        postRequest: state.postRequest,
        getRequest: state.getRequest,
        getData: state.getData,
        check: state.check,
        url: state.url,
        memory: state.memory,
    }), shallow)
    const history = useHistory();
    
    useEffect(() => {
        console.log(memory);
        
    }, [])
    const action = () =>{
        yaokiskiService.setState({ memory: {}})
        localStorage.clear();
        history.push("https://www.officemax.com.mx/");
    }
    return (
        <div className='container'>
            <div className="row justify-content-center">
                <div className="col-md-9 col-12 text-center pt-4">
                    <h1 className="h-finally-vale pt-5">ENCUESTA FINALIZADA</h1>
                    <h6 className="pt-5 pb-5">Te hemos enviado a tu correo electrónico una copia de tu cupón</h6>
                    <h5 className="pt-5 pb-4">Tu código de cupón es:</h5>
                    <img src={memory.vale.url}></img>
                    <p className="pt-5 pb-5">{memory.vale.vale}</p>
                    <div className="text-center pt-5 pb-5">
                        <a  type="button" href='https://www.officemax.com.mx' className="btn btn-lg btn-office btn-vale" id="go">Ir a OfficeMax</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
