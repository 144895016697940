import './App.css';
// import Home from './views/Landing/home';
import Routes from './routes';
// import logo from './logo.svg';


function App() {
  return (
    <div className="">
      <Routes/>
    </div>
  );
}

export default App;
