import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

export default function NotFound() {

    return (
        <div className='container'>
            <div className="row justify-content-center">
                <div className="col-md-9 col-12 text-center pt-5 pb-5">
                    <br/>
                    <br/>
                    <FontAwesomeIcon className='icon-error' icon={faTimesCircle} />
                    <h1 className="h-finally-vale pt-5">Página no encontrada</h1>
                    <br/>
                    <br/>
                    <div className="text-center pt-5 pb-5">
                        <a  type="button" href='https://tuvozalmax.com' className="btn btn-lg btn-office btn-vale" >Ir al inicio</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
