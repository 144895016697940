import React from 'react'
import yaokiskiService from '../../lib/yaokiski'
import shallow from 'zustand/shallow'
import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


export default function Questionnaire() {
    const [answers, setAnswers] = useState({});
    const [openAnswers, setOpenAnswers] = useState({});
    const [errors, setErrors] = useState({});
    const [serviceQuestions, setServiceQuestions] = useState(0);
    // const [titles, setTitles] = useState({});
    const MySwal = withReactContent(Swal);
    let titles = [];
    const history = useHistory();

    // const [section0, setSection0] = useState(true);
    // const [section1, setSection1] = useState(false);
    // const [section2, setSection2] = useState(false);
    // const [section3, setSection3] = useState(false);
    // const [section4, setSection4] = useState(false);
    // const [section5, setSection7] = useState(false);
    const [sections, setSections] = useState([true,false,false,false,false,false]);


    const {postRequest,getRequest,getData,check,url,memory,getToken} = yaokiskiService(state => ({
        postRequest: state.postRequest,
        getRequest: state.getRequest,
        getData: state.getData,
        check: state.check,
        url: state.url,
        memory: state.memory,
        getToken: state.getToken,
    }), shallow)

    const rootId = document.getElementById( 'root' );
    rootId.style.backgroundColor="#e0e0e0";

    // useEffect(() => {
    //     console.log(memory);
    //     console.log(getToken());
    // }, []);

    useEffect(() => {
        console.log(answers);

    }, [answers]);
    useEffect(() => {
        console.log(errors);
    }, [errors]);

    
    const validateTitle = (title) => {
        if (titles.includes(title)){
            // console.log(titles);
            return false;
        }
        else{
            titles.push(title)
            // console.log(titles);
            return true;
        }
    }

    const goToViolation = (id) =>{
        const titleElement = document.getElementById(id);
        titleElement.scrollIntoView({ behavior: 'smooth' })
    };

    const action = () => {
        MySwal.fire({
            "allowOutsideClick": false,
            "title": "Enviando resultados",
            "text": "Espere un momento por favor.",
        })
        MySwal.showLoading();
 
        let dataAnswers = {};
        let data_errors = {};
        let form_valid = true;
 
        // let response = '';

        // itera respuestas para validar encuesta
        Object.keys(memory.quest).forEach(function(key_section) {
            Object.keys(memory.quest[key_section]).forEach(function(key) {
                // console.log(memory.quest[key_section][key].id);
                // if (parseInt(key) !== (memory.quest.length - 1)) { //evita revisar la ultima pregunta que son los comentarios

                // if (serviceQuestions == 2) {
                    
                //     console.log();

                // }else{
                    data_errors[`quest${memory.quest[key_section][key].id}`] = '';
                    
                    if (!answers[`quest${memory.quest[key_section][key].id}`]) {
                        console.log(serviceQuestions);
                        if (((serviceQuestions == 0) && (memory.quest[key_section][key].id == 54)) || ((serviceQuestions == 0) && (memory.quest[key_section][key].id == 55))) {
                            
                        }else{

                            data_errors[`quest${memory.quest[key_section][key].id}`] = 'Pregunta requerida'
                        }
                    }else{
                        if (answers[`quest${memory.quest[key_section][key].id}`].length > 1) {
                            // extrae caracteres para identificar si es respuesta de una pregunta abierta
                            let valFirst = '';
                            let valRest = '';
                                valFirst = answers[`quest${memory.quest[key_section][key].id}`].substr(0, 2);
                                valRest = answers[`quest${memory.quest[key_section][key].id}`].substr(2, answers[`quest${memory.quest[key_section][key].id}`].length);
    
                            if ((valFirst == 'R:') && valRest.length < 4) { // identifico si la respuesta es abierta y es mayor a 10 caracteres
                                data_errors[`quest${memory.quest[key_section][key].id}`] = 'La respuesta debe contener minimo 5 caracteres.'
                            }
                        }
                    }

                // }
 
            });
        });
        console.log(data_errors);
        setErrors(data_errors);
        Object.values(data_errors).forEach((val , text) => val.length > 0 && (form_valid = false) );
        
        if (!form_valid) {
            
            MySwal.fire( {
                "confirmButtonText": "Aceptar",
                "reverseButtons": true,
                "text": "Complete la encuesta.",
                "title": "Atención",
                "icon": "warning",
                "iconColor": '#ffcd00',
                "confirmButtonColor":'#000000',
            } ).then( ( result ) => {
                setTimeout(() => {

                    //obtengo el id de la primer pregunta con error
                    let id_val = ''
                    for (var [key, value] of Object.entries(data_errors)) {
                        if (value != '') {
                            id_val = 'the'+key
                            break;
                        }
                    }
                    // ejecuta scrolll al error
                    // goToViolation(id_val)

                }, 400);
            } );

            return;
        }

        
        
        // if(!answers['quest'+(memory.quest.length - 1)]){
        //     answers['quest'+(memory.quest.length - 1)] = '';
        // }

        dataAnswers.answers = answers;
        dataAnswers.token = getToken();

        window.grecaptcha.ready(function() {
            window.grecaptcha.execute('6Lc9bIQcAAAAAOjH5al43-hndoUptX5448_aul3p')
            .then(function(token) {
                dataAnswers.g_recaptcha_token = token;

                postRequest(url.api+'/api/v1/app/answers', dataAnswers)
                .then(function (response){
                    console.log(response);
                    MySwal.close();
                    if (check(response)) {
                        let data = getData(response);
                        // yaokiskiService.setState({ cupon: { "cupon" : 'kjghkhkjhkjhkjhj,hkjhkjhkjhkj'}})
                        yaokiskiService.setState({ memory: {...memory, "vale" : getData(response.data)}})

                        history.push("/vale")
                    }else{
                        MySwal.fire( {
                            "iconColor": '#ffcd00',
                            "confirmButtonColor":'#000000',
                            "confirmButtonText": "Aceptar",
                            "text": "Por el momento no se puede realizar la operación, intente de nuevo más tarde.",
                            "title": "Atención",
                            "icon": "error"
                        } );
                    }
                }, ( error ) => {
                    MySwal.fire( {
                        "iconColor": '#ffcd00',
                        "confirmButtonColor":'#000000',
                        "confirmButtonText": "Aceptar",
                        "text": "Por el momento no se puede realizar la operación, intente de nuevo más tarde.",
                        "title": "Atención",
                        "icon": "error",
                        "footer": '<p style="font-size:9px;">'+error+'</p>'
                    } );
                })
            });
        });
    }

    const nextSection = (index_section) => {
        const updatedSections = [...sections];
        updatedSections[index_section] = false;
        updatedSections[index_section+1] = true;
        setSections(updatedSections)
        console.log(sections);
    }
    const backSection = (index_section) => {
        const updatedSections = [...sections];
        updatedSections[index_section] = false;
        updatedSections[index_section-1] = true;
        setSections(updatedSections)
        console.log(sections);
    }

    const openAnswersChange = (value_quest) =>{
        setAnswers({...answers, [`quest${value_quest.id}`]: ''})
        setOpenAnswers({...openAnswers, [`quest${value_quest.id}`]: true})
    }

    const answersChange = (value,value_quest) =>{
        console.log(value);
        console.log(value_quest);


        setAnswers({...answers, [`quest${value_quest.id}`]: value})
        setOpenAnswers({...openAnswers, [`quest${value_quest.id}`]: false})


        //muestra y oculta las preguntas de atención al cliente
        if ((value_quest.id == '53') && (value != 'No requirió atención.') ) {

            var element = document.getElementsByClassName("service");
            //oculta las dos preguntas
            element[0].classList.remove("d-none");
            element[1].classList.remove("d-none");
            //limpia los radio buttons
            var inputsFirst = document.getElementsByName("quest54");
            console.log(inputsFirst);
            inputsFirst[0].checked=false;
            inputsFirst[1].checked=false;
            inputsFirst[2].checked=false;
            inputsFirst[3].checked=false;
            inputsFirst[4].checked=false;

            var inputsSecond = document.getElementsByName("quest55");
            inputsSecond[0].checked=false;
            inputsSecond[1].checked=false;
            inputsSecond[2].checked=false;
            inputsSecond[3].checked=false;
            inputsSecond[4].checked=false;

            setServiceQuestions(2)
        }

        if ((value_quest.id == '53') && (value == 'No requirió atención.') ) {
            //se elimino los valores guardados en answers
            var element = document.getElementsByClassName("service");
            element[0].classList.add("d-none");
            element[1].classList.add("d-none");
            setServiceQuestions(0)
            delete answers['quest54']
            delete answers['quest55']
            //setAnswers(answers)
            setAnswers({...answers, [`quest${value_quest.id}`]: value})

        }


    }

    return (
        <div className='container'>
            <div className="row justify-content-center quest-content">
                {/* {'total: '+memory.count_quest}
                {'conteo: '+ Object.keys(answers).length}
                {'porcentaje =' + Math.round((Object.keys(answers).length/memory.count_quest) * 100) } */}
                {memory.count_quest == 28 ? 
                <div className='progress-top'>
                    <h2 className='text-center'>Completado {Math.round((Object.keys(answers).length  /memory.count_quest) * 100)} %</h2>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{'width': Math.round((Object.keys(answers).length / memory.count_quest) * 100)+'%'}} aria-valuenow={Math.round((Object.keys(answers).length /memory.count_quest ) * 100)} aria-valuemin="0" aria-valuemax="100">{Math.round((Object.keys(answers).length/memory.count_quest) * 100)}%</div>
                    </div>
                </div>
                :
                <div className='progress-top'>
                    <h2 className='text-center'>Completado {Math.round((Object.keys(answers).length   /((memory.count_quest-2) + serviceQuestions)) * 100)} %</h2>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{'width': Math.round((Object.keys(answers).length  /((memory.count_quest-2)+serviceQuestions)) * 100)+'%'}} aria-valuenow={Math.round((Object.keys(answers).length /((memory.count_quest - 2) + serviceQuestions)) * 100)} aria-valuemin="0" aria-valuemax="100">{Math.round((Object.keys(answers).length/((memory.count_quest - 2) + serviceQuestions)) * 100)}%</div>
                    </div>
                </div>
                }
                <div className="col-md-8 col-12">
                    {/* <p>Atención Personal de Venta</p> */}
                    {memory.quest.map((section_quest, index_section) => (
                        <div key={index_section} className={sections[index_section] ? `` : `d-none`}>
                            {section_quest.map((value_quest, index) => (
                                // {value_quest.questionnaire_id == '53' ? '': ''}
                                <div key={index} className={(value_quest.id == '54' || value_quest.id == '55') ? 'form-group d-none service': 'form-group'} id={'thequest'+index}>
                                    { validateTitle(value_quest.slug) ? <h2 className="pt-4">{value_quest.subtitle}</h2> : '' }
                                    <label className="mt-3 mb-1" >{index+1}. {value_quest.question} <span className='brand-office'>*</span>  </label>
                                    <div className= {value_quest.type == 'rango' ? 'content-answers content-rango' : 'content-answers'}>
                                    <p className='description-rango'>{value_quest.description}</p>
                                    {value_quest.type == 'seleccion' || value_quest.type == 'Si/No' || value_quest.type == 'rango' || value_quest.type == 'Si/No/comentario' || value_quest.type == 'seleccion/comentario'?
                                        value_quest.answer_banks?.map((value_answer_banks, index_answer_banks) => (
                                                <div key={index_answer_banks} className={value_quest.type == 'rango' ? 'pl-5 rango' : 'pl-5'}>
                                                {((value_quest.type == 'Si/No/comentario') || (value_quest.type == 'seleccion/comentario')) && ((value_quest.answer_banks.length -1 ) == index_answer_banks) ?
                                                    <>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={[`quest${value_quest.id}`]} id={[`quest${index}${index_answer_banks}`]}
                                                                value={value_answer_banks.answer}
                                                                onChange={({ target: { value } }) => openAnswersChange(value_quest)}
                                                                // onChange={({ target: { value } }) => setAnswers({...answers, [`quest${value_quest.id}`]: ''})}
                                                            />
                                                            <label className="form-check-label" htmlFor={[`quest${index}${index_answer_banks}`]}>
                                                                {value_answer_banks.answer}
                                                            </label>
                                                        </div>
                                                        {openAnswers[`quest${value_quest.id}`] == true ?
                                                        <textarea className="form-control" minLength="10" type="textarea" 
                                                        onChange={({ target: { value } }) => setAnswers({...answers, [`quest${value_quest.id}`]: value != '' ? 'R:'+ value_answer_banks.answer + ', ' +value: ''})}
                                                        /> 
                                                        : ''}
                                                    </>
                                                : <div className="form-check">
                                                    <input className="form-check-input" type="radio" name={[`quest${value_quest.id}`]} id={[`quest${index}${index_answer_banks}`]}
                                                        value={value_answer_banks.answer}
                                                        onChange={({ target: { value } }) => answersChange(value,value_quest)}
                                                    />
                                                    <label className="form-check-label" htmlFor={[`quest${index}${index_answer_banks}`]}>
                                                        {value_answer_banks.answer}
                                                    </label>
                                                </div>}
                                            </div>
                                        ))
                                    : ''}
                                    {value_quest.type == 'comentario' ? 
                                        <>
                                            <textarea className="form-control" type="textarea" 
                                                onChange={({ target: { value } }) => answersChange(value,value_quest)}
                                            />
                                        </>
                                    : ''}
                                    </div>
                                    {errors[`quest${value_quest.id}`] && 
                                    <span className='error error-quest pl-5'>{errors[`quest${value_quest.id}`]}</span>} 
                                </div>
                            ))}
                            <div className="text-center">
                                <a type="button" className={index_section > 0 ? `btn btn-office mt-5 me-5` : `d-none`} onClick={(e) => backSection(index_section, e)}>ATRAS</a>
                                <a type="button" className={index_section < memory.quest.length-1 ? `btn btn-office mt-5 ms-5` : `d-none`} onClick={(e) => nextSection(index_section, e)}>SIGUIENTE</a>
                            </div>
                            <div className="text-center pt-5 mb-5">
                                <a type="button" className={index_section == memory.quest.length-1 ? `btn btn-office`: `d-none`} onClick={action} >FINALIZAR ENCUESTA</a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
