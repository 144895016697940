import axios from "axios";

const baseURL = 'https://pokeapi.co/api/v2/'

export default (url, method, data, headers ) => 
    axios({
            baseURL,
            method,
            url,
            data,
            headers : {
                'Content-Type': method == 'get' ? 'x-www-form-urlencoded' : 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
    });
