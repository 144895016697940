import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp,faInstagram,faFacebook,faYoutube,faTwitter,faLinkedin } from '@fortawesome/free-brands-svg-icons'

export default function Footer() {
    return (
        <div className=" footer">
            <div className="container">
                <div className="row">
                    {/* <div className="col-12 text-center">
                        <img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} alt="logo"/>
                        <br></br>
                        <p>© 2021 OfficeMax. Todos los derechos reservados.</p>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                    </div> */}
                    <div className="col-12 text-center">
                        <br />
                        <p>Compra en <a href="https://www.officemax.com.mx" target="_blank">www.officemax.com.mx</a> &nbsp;&nbsp; Contáctanos  &nbsp;&nbsp;<FontAwesomeIcon icon={faPhoneVolume} /> <a href="tel:5555650629" >55-5565-OMAX (0629)</a> &nbsp;&nbsp;<FontAwesomeIcon icon={faWhatsapp} /> <a href="https://wa.me/525522702987" target="_blank">55-2270-2987</a></p>
                        {/* <br /> */}
                        <p className='social-media'>
                            <a href='http://instagram.com/officemax_mx/' target="_blank"><FontAwesomeIcon icon={faInstagram} /> officemax_mx</a> &nbsp;&nbsp;
                            <a href='https://www.facebook.com/OfficeMaxMx/' target="_blank"><FontAwesomeIcon icon={faFacebook} /> OfficeMaxMx</a> &nbsp;&nbsp;
                            <a href='https://twitter.com/OfficeMax_mx' target="_blank"><FontAwesomeIcon icon={faTwitter} /> OfficeMax_mx</a> &nbsp;&nbsp;
                            <a href='https://www.youtube.com/channel/UCBOL-rq6D-pjS_iqm9o0akw' target="_blank"><FontAwesomeIcon icon={faYoutube} /> OfficeMaxMéxico</a> &nbsp;&nbsp;
                            <a href='https://linkedin.com/company/officemax-m%C3%A9xico' target="_blank"><FontAwesomeIcon icon={faLinkedin} /> OfficeMax México</a> &nbsp;&nbsp;
                        </p>
                        
                        <br />
                        <br />
                        <p>© 2022 OfficeMax. Todos los derechos reservados.</p>
                        <br />
                        <br />
                    </div>
                
                </div>
            </div>
            
        </div>
    )
}
