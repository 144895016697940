import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import PrivateRoute from "./PrivateRoute";
import Home from '../views/Landing/home';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import About from "../views/Landing/about";
import Questionnaire from "../views/Landing/questionnaire";
import Vale from "../views/Landing/vale";
import NotFound from "../views/Landing/notfound";

export default function Routes() {
    return (
        <Router>
            <Navbar/>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/encuesta" component={Questionnaire} />
                <Route path="/vale" component={Vale} />
                <Route path="*" component={NotFound} />
            </Switch>
            <Footer/>
        </Router>

    )
}