import { Link, NavLink } from "react-router-dom"

export default function Navbar() {
    return (
      <header>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12 text-center">
                <h1 className="title-tuvoz">TU VOZ <strong>AL MAX</strong> </h1>
            </div>
            <div className="col-lg-4 col-12 text-center">

               <img className="img-fluid logo-subrayado" src={`${process.env.PUBLIC_URL}/assets/images/logo-subrayado.jpg`} alt="logo"/>
            </div>
          </div>
          {/* <nav>
              <h1>Navbar</h1>
              <ul>
                  <li>
                      <NavLink exact to="/" activeClassName="active">Home</NavLink>
                  </li>
                  <li>
                      <NavLink to="/about" activeClassName="active">About</NavLink>
                  </li>
                  
              </ul>
          </nav> */}
        </div>
      </header>
    )
}
